import awsConfig from 'src/config/aws'
import appConfig from 'src/config/app'
import { Consent3 } from 'src/components/AuthComponent/components/Consent3'

import A6YReactAuth from '@altalogy/a6y-react-auth'
import { PaymentHeader } from 'src/components/AuthComponent/components/PaymentHeader'
import { ForgotPasswordHeader } from 'src/components/AuthComponent/components/ForgotPasswordHeader'
import { AuthFormLinks } from 'src/components/AuthComponent/components/AuthFormLinks'
import { SetNewPasswordHeader } from 'src/components/AuthComponent/components/SetNewPasswordHeader'
const ReactAuth = new A6YReactAuth()

const socialLoginStyle =
  'w-full px-6 py-2.5 my-4 flex flex-row items-center justify-center rounded-md shadow border hover:shadow-sm'
const socialLoginIconStyle = 'w-5 h-5 mr-5'
const socialLoginTextStyle = 'text-sm font-semibold w-36 max-w-full text-left'

export const initMiniSignInSignUpBuyCredits = () => {
  ReactAuth.initialize({
    provider: {
      type: 'cognito',
      userPoolId: awsConfig.Auth.userPoolId,
      userPoolWebClientId: awsConfig.Auth.userPoolWebClientId,
      identityPoolId: '',
      region: awsConfig.Auth.region,
      oauthDomain: awsConfig.Auth.oauthDomain,
      oauthRedirectSignIn: `${appConfig.WEB_URL}/buy-credits?purchasing=true`,
      oauthRedirectSignOut: `${appConfig.WEB_URL}/sign-in/`,
    },
    components: {
      Inputs: {
        labels: true,
      },
      signUp: {
        headerComponent: (
          <PaymentHeader title='Sign up to proceed to checkout 💫' />
        ),
        linksComponent: {
          customLinksComponent: <AuthFormLinks formType='sign-up' />,
        },
        social: {
          facebook: {
            buttonText: 'Sign up Facebook',
          },
          google: {
            buttonText: 'Sign up Google',
          },
        },
        confirmation: true,
      },
      signIn: {
        headerComponent: (
          <PaymentHeader title='Sign in to proceed to checkout 💫' />
        ),
        linksComponent: {
          customLinksComponent: <AuthFormLinks formType='sign-in' />,
        },
        social: {
          facebook: {
            buttonText: 'Login Facebook',
          },
          google: {
            buttonText: 'Login Google',
          },
        },
      },
      consents: {
        display: 'sign-up',
        position: 'top',
        consents: [
          {
            name: 'terms',
            type: 'other',
            required: false,
            content: <Consent3 />,
            isElement: true,
          },
        ],
      },
      forgotPassword: {
        headerComponent: <ForgotPasswordHeader title={'FORGOT_PASSWORD'} />,
        linksComponent: {
          customLinksComponent: <AuthFormLinks formType='forgot-password' />,
        },
      },
      forgotPasswordSubmit: {
        headerComponent: (
          <SetNewPasswordHeader title={'FORGOT_PASSWORD_SUBMIT'} />
        ),
      },
    },
    auth: [
      {
        appId: awsConfig.Auth.facebookProviderAppId,
        provider: 'facebook',
        className:
          socialLoginStyle + ' bg-brands-facebook border-brands-facebook',
        iconClassName: socialLoginIconStyle,
        textClassName: socialLoginTextStyle + ' text-white',
      },
      {
        appId: awsConfig.Auth.googleProviderAppId,
        provider: 'google',
        className: socialLoginStyle + ' bg-white border-gray-300',
        iconClassName: socialLoginIconStyle,
        textClassName: socialLoginTextStyle,
      },
    ],
  })
}
