import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, ButtonStyle } from '../../UI/Button'
import { Input } from '../../UI/Inputs'
import { Logo } from '../../UI/Logo'

export const ConfirmMigrationForm = ({
  code,
  onCodeChange,
  onSubmit,
  invalid,
  error,
  setStep,
}: {
  code: string
  onCodeChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  invalid?: boolean
  error?: string
  setStep: (step: number) => void
}): JSX.Element => {
  const { t } = useTranslation(['auth', 'app'])
  return (
    <div className='pb-2 mb-2'>
      <div>
        <div className='flex items-center justify-center md:justify-start font-semibold'>
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <h1 className='mb-5'>{t('CONFIRM_MIGRATION_TITLE')}</h1>
        <p>{t('CONFIRM_MIGRATION_CODE_DESC')}</p>
        <Input
          typeInput='text'
          id='code'
          onChange={onCodeChange}
          label={t('CONFIRM_MIGRATION_CODE_LABEL')}
          value={code}
          placeholder={t('CONFIRM_MIGRATION_CODE_PLACEHOLDER')}
          labelStyles='text-xs font-medium capitalize text-blue-500'
          inputStyles='shadow-input text-sm h-10 w-full border text-black rounded mt-1 mb-3 p-2'
          validBorderColor='border-blue-500'
          invalid={invalid}
          error={error ? t(error) : undefined}
        />
        <div className='pt-6 flex flex-col items-center'>
          <Button
            className='max-w-max p-3 sm:px-6 sm:py-3 mt-4 font-normal text-sm'
            onClick={onSubmit}
          >
            {t('app:SUBMIT')}
          </Button>
          <Button
            className='max-w-max p-2 sm:px-6 sm:py-2 mt-4'
            style={ButtonStyle.NOBORDERS}
            onClick={() => setStep(0)}
          >
            {t('app:BACK')}
          </Button>
        </div>
      </div>
    </div>
  )
}
