/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

// Components
import { Logo } from '../Logo'
import { Link, LinkStyle } from '../Link'
import { Breadcrumbs } from '../Breadcrumbs'
import { Button, ButtonStyle } from '../Button'
import { Container } from '../ContainerComponent'
import { Dropdown, DropdownItem } from '../Dropdown'
import FinishLaterModal from '../../../components/AddSurvey/components/FinishLaterModal'
import { BsFillLightningFill } from 'react-icons/bs'

// Services
import UserService from '../../../services/UserService'
import AnalyticsSegmentService from '../../../services/AnalyticsSegmentService'
// Assets
import ActiveUserAvatar from '../../../assets/Navbar/person-outline-1-active.svg'
import MaleAvatar from '../../../assets/Avatars/male-avatar.svg'
import FemaleAvatar from '../../../assets/Avatars/female-avatar.svg'

// Miscellaneous
import { CurrentUserContext } from '../../../App'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'
import { emptyNavbarUrls } from '../../../config/navbar/emptyNavbarUrls'
import { NotificationsContainer } from './components/NotificationsContainer'
import { OverviewMenu } from './components/OverviewMenu'
import SubNavbar from './SubNavbar'
import {
  DISCOUNT_RATE,
  PROMO_CODE,
  SURVEYS_FILLED_COUNT_BEFORE_DISCOUNT,
} from '../../../config/discounts'

const getAvatar = currentUser => {
  if (!currentUser) {
    return ActiveUserAvatar
  } else {
    return currentUser.getAvatarImagePath()
  }
}
/**
 * @typedef INavbarProps
 * @props {string} [className] - the CSS classes.
 */

export interface INavbarProps {
  className?: string
  isNavbarFixed?: boolean
}

/**
 * Renders the navbar component.
 *
 * @param {string} [className] - the CSS classes.
 *
 * @example
 *  <Navbar />
 */

export const Navbar: React.FunctionComponent<INavbarProps> = ({
  className = 'flex items-center justify-between w-full border-b border-gray-600 h-16 shadow',
  isNavbarFixed = true,
}: INavbarProps) => {
  function urlActive(pathname: string): boolean {
    return window.location.pathname === pathname
  }
  const { t } = useTranslation('nav')
  const [showFinishLaterModal, setShowFinishLaterModal] = useState<boolean>(
    false,
  )

  const [showDiscountSubNavbar, setShowDiscountSubNavbar] = useState<boolean>(
    true,
  )
  const [showPromoCodeSubNavbar, setShowPromoCodeSubNavbar] = useState<boolean>(
    true,
  )
  const [
    showFirst24HourSubNavbar,
    setShowFirst24HourSubNavbar,
  ] = useState<boolean>(false)

  const location = useLocation()

  const { currentUser } = useContext(CurrentUserContext)

  useEffect(() => {
    const discountBannerShown = localStorage.getItem('discount_banner_shown')
    if (
      location.pathname === '/surveys' &&
      !discountBannerShown &&
      currentUser &&
      currentUser.filled_surveys < SURVEYS_FILLED_COUNT_BEFORE_DISCOUNT
    ) {
      setShowDiscountSubNavbar(true)
    } else {
      setShowDiscountSubNavbar(false)
    }

    const promoCodeBannerShown = localStorage.getItem('promo_code_banner_shown')
    if (
      !promoCodeBannerShown &&
      currentUser &&
      currentUser.filled_surveys >= SURVEYS_FILLED_COUNT_BEFORE_DISCOUNT
    ) {
      setShowPromoCodeSubNavbar(true)
    } else {
      setShowPromoCodeSubNavbar(false)
    }

    // Check if currentUser and currentUser.created_at are not null before proceeding
    if (currentUser && currentUser.created_at) {
      const createdAt = new Date(currentUser.created_at)
      const now = new Date()
      const timeDiff = now.getTime() - createdAt.getTime() // Difference in milliseconds
      const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000

      const isLessThan24Hours = timeDiff < twentyFourHoursInMilliseconds

      const firstSurveyBannerShown = localStorage.getItem(
        'first_survey_banner_shown',
      )
      if (!firstSurveyBannerShown && isLessThan24Hours) {
        setShowFirst24HourSubNavbar(false)
      } else {
        setShowFirst24HourSubNavbar(false)
      }
    } else {
      // Handle the case where currentUser or currentUser.created_at is null
      setShowFirst24HourSubNavbar(false)
    }
  }, [currentUser])

  // Generic function to handle subnavbar closure
  const handleCloseSubNavbar = (setSubNavbarState, localStorageKey) => {
    return () => {
      setSubNavbarState(false)
      localStorage.setItem(localStorageKey, 'false')
    }
  }
  const history = useHistory()

  const onFinishLaterModel = async () => {
    AnalyticsSegmentService.track('Add Survey Process Stopped', {})

    history.push(includeCurrentLocaleToPath('/surveys?showModal=true'))
  }

  const displayBuyButton = () => {
    const excludedPaths = ['/buy-credits', '/add-survey']

    return excludedPaths.every(path => !window.location.pathname.includes(path))
  }

  const displayDashboardButton = () => {
    const excludedPaths = ['/dashboard', '/surveys', '/surveys', '/add-survey']

    return excludedPaths.every(path => !window.location.pathname.includes(path))
  }

  const trackAndOpenBuyCreditsModal = () => {
    AnalyticsSegmentService.track('Payment Modal Opened', {
      location: 'navbar',
    })
    history.push('/buy-credits')
  }

  const activeStyle = 'text-blue-500 border-blue-600 bg-blue-700'

  const signOut = async () => {
    try {
      UserService.signOut()
    } catch (error) {
      toast.error(t('ERROR_CANNOT_SIGN_OUT'))
    }
  }

  return (
    <CurrentUserContext.Consumer>
      {({ currentUser }) => {
        const logoLink = currentUser ? '/surveys?showModal=true?' : '/'

        return (
          <div className={`flex flex-col ${isNavbarFixed ? 'pt-16' : ''}`}>
            <nav
              className={classNames(className, {
                ['fixed bg-white z-20 top-0']: isNavbarFixed,
              })}
            >
              <Container className='pr-0 pl-0 flex items-center justify-between w-full mx-auto sm:max-w-screen-xl'>
                <div className='flex items-center flex-1'>
                  {urlActive('/add-survey') ? (
                    <Button
                      style={ButtonStyle.NOBORDERS}
                      className='opacity-100'
                      onClick={() => setShowFinishLaterModal(true)}
                    >
                      <Logo
                        logotypeClassName='hidden sm:block'
                        className='flex items-center justify-between p-1 sm:p-2 m-1 w-12 sm:w-36'
                      />
                    </Button>
                  ) : (
                    <Link href={logoLink} style={LinkStyle.NONE}>
                      <Logo
                        logotypeClassName='hidden sm:block'
                        className='flex items-center justify-between p-1 sm:p-2 m-1 w-12 sm:w-36'
                      />
                    </Link>
                  )}
                  <Breadcrumbs />
                </div>
                <div className='flex'>
                  {/* {!emptyNavbarUrls(window.location.pathname) && ( */}
                  <div className='flex items-center justify-end flex-1 ml-6'>
                    <div className='flex rounded'>
                      {displayBuyButton() && (
                        <Button
                          className='rounded-md my-2 mr-4 px-4 py-2 text-sm'
                          style={ButtonStyle.BLUE}
                          onClick={() => trackAndOpenBuyCreditsModal()}
                        >
                          <BsFillLightningFill></BsFillLightningFill>
                          Buy Respondents
                        </Button>
                      )}
                    </div>
                    {currentUser && (
                      <>
                        {window.location.pathname !== '/surveys' && (
                          <Button
                            className='rounded-md my-2 mr-4 px-4 py-2 text-sm'
                            style={ButtonStyle.TERTIARY}
                            href='/surveys'
                          >
                            Go to overview
                          </Button>
                        )}

                        <NotificationsContainer></NotificationsContainer>

                        <Dropdown
                          menuStyles='origin-top-right absolute right-0 mt-2 w-32 py-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-10 bg-white'
                          menuButtonStyles={`bg-gray-700 text-black rounded-md border border-gray-600 my-2 px-2 py-2 text-sm
                    ${urlActive('/surveys') && activeStyle}
                  `}
                          button={
                            <img
                              src={getAvatar(currentUser)}
                              alt='User Avatar'
                              style={{ height: '21px' }}
                            />
                          }
                        >
                          <DropdownItem className='px-3'>
                            <Link
                              href='/surveys'
                              className='block hover:bg-gray-700 px-4 py-2 text-sm'
                              style=''
                            >
                              Overview
                            </Link>
                          </DropdownItem>
                          <DropdownItem className='px-3'>
                            <Link
                              href='/my-profile'
                              className='block hover:bg-gray-700 px-4 py-2 text-sm'
                              style=''
                            >
                              My profile
                            </Link>
                          </DropdownItem>
                          <DropdownItem className='px-3 cursor-pointer'>
                            <p
                              className='block hover:bg-gray-700 px-4 py-2 text-sm'
                              onClick={signOut}
                            >
                              Log out
                            </p>
                          </DropdownItem>
                        </Dropdown>
                      </>
                    )}
                    {/* Dropdown - Profile */}
                  </div>
                  {/* )} */}
                </div>
              </Container>
              <FinishLaterModal
                modalOpen={showFinishLaterModal}
                closeModal={() => setShowFinishLaterModal(false)}
                onFinishLaterModel={onFinishLaterModel}
              />
            </nav>
            {/* {showDiscountSubNavbar && (
              <SubNavbar
                bgColor='bg-green-700'
                text={`Fill out ${SURVEYS_FILLED_COUNT_BEFORE_DISCOUNT} surveys to unlock a ${DISCOUNT_RATE}% discount code for all purchases 🎉`}
                onClose={handleCloseSubNavbar(
                  setShowDiscountSubNavbar,
                  'discount_banner_shown',
                )}
              />
            )} */}
            {/*
            {showPromoCodeSubNavbar && (
              <SubNavbar
                bgColor='bg-blue-700'
                text={`Promocode unlocked, use "${PROMO_CODE}" for a ${DISCOUNT_RATE}% discount on all purchases 🎉`}
                onClose={handleCloseSubNavbar(
                  setShowPromoCodeSubNavbar,
                  'promo_code_banner_shown',
                )}
              />
            )} */}

            {showFirst24HourSubNavbar && (
              <SubNavbar
                bgColor='bg-orange-700'
                text={`We will boost you're survey for the 24 hours as a welcome, so get as many Karma as possible`}
                onClose={handleCloseSubNavbar(
                  setShowPromoCodeSubNavbar,
                  'promo_code_banner_shown',
                )}
              />
            )}
          </div>
        )
      }}
    </CurrentUserContext.Consumer>
  )
}
