import { useEffect, useState } from 'react'
import { fetchReviewsConfiguration } from 'src/config/reviews'

export const withReviewsConfiguration = (
  WrappedComponent: React.ComponentType<any>,
) => {
  return props => {
    const [reviewsConfiguration, setReviewsConfiguration] = useState({})

    useEffect(() => {
      fetchReviewsConfiguration().then(config =>
        setReviewsConfiguration(config),
      )
    }, [])

    return (
      <WrappedComponent
        {...props}
        reviewsConfiguration={reviewsConfiguration}
      />
    )
  }
}
