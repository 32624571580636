import React from 'react'
import {
  FaRegLightbulb,
  FaExclamationCircle,
  FaTimesCircle,
} from 'react-icons/fa'

type InformationTooltipProps = {
  text: string
  variant?: 'success' | 'warning' | 'danger'
  buttonText?: string
  buttonLink?: string
}

const InformationTooltip = ({
  text,
  variant = 'success',
  buttonText,
  buttonLink,
}: InformationTooltipProps) => {
  const variants = {
    success: {
      bg: 'bg-green-50',
      border: 'border-green-100',
      text: 'text-green-800',
      icon: 'text-green-600',
      Icon: FaRegLightbulb,
      button: 'bg-green-400 hover:bg-green-300',
    },
    warning: {
      bg: 'bg-orange-50',
      border: 'border-orange-100',
      text: 'text-orange-800',
      icon: 'text-orange-600',
      Icon: FaExclamationCircle,
      button: 'bg-orange-400 hover:bg-orange-300',
    },
    danger: {
      bg: 'bg-red-50',
      border: 'border-red-100',
      text: 'text-red-800',
      icon: 'text-red-600',
      Icon: FaTimesCircle,
      button: 'bg-red-400 hover:bg-red-300',
    },
  }

  const style = variants[variant]
  const Icon = style.Icon

  return (
    <div
      className={`${style.bg} border ${style.border} rounded-lg p-4 flex items-center gap-3`}
    >
      <Icon className={`${style.icon} flex-shrink-0 w-5 h-5`} />
      <div className='flex flex-1 items-center justify-between gap-4'>
        <p className={`${style.text} font-medium text-sm`}>{text}</p>
        {buttonText && buttonLink && (
          <a
            href={buttonLink}
            className={`${style.button} text-white px-4 py-2 rounded-md text-sm font-medium whitespace-nowrap`}
          >
            {buttonText}
          </a>
        )}
      </div>
    </div>
  )
}

export default InformationTooltip
