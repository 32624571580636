import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Logo } from '../../UI/Logo'

export const ConfirmMigrationHeader = (): JSX.Element => {
  const { t } = useTranslation('auth')
  return (
    <div className='border-b border-gray-200 pb-2 mb-2'>
      <div>
        <div className='flex items-center justify-center md:justify-start font-semibold'>
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <h1 className='mb-5'>{t('CONFIRM_MIGRATION_TITLE')}</h1>
        <p>{t('CONFIRM_MIGRATION_DESC')}</p>
      </div>
    </div>
  )
}
