import './GoogleReviewWidget.css'

const defaultWidgetConfig = {
  score: 4.7,
  url: 'https://www.trustpilot.com/review/surveyswap.io',
  reviewsCount: 222,
}

export const ReviewStars = ({ rating = 5 }) => {
  return (
    <div id='tm-w486xf'>
      <div className='go1038471815 tm-overall-stars inline'>
        <div className='go271816224 tm-stars'>
          <div style={{ width: `calc((${rating} * 100%) / 5)` }}></div>
        </div>
      </div>
    </div>
  )
}

export const GoogleLogo = () => {
  return (
    <div id='tm-w486xf' className='inline-block'>
      <div
        aria-label='Google'
        className='go3304816377 tm-source-logo tm-source-logo--google inline'
      >
        <img
          src='https://d2nce6johdc51d.cloudfront.net/source/google.svg'
          alt='Google Reviews'
          className='go1914879238'
        />
      </div>
    </div>
  )
}

const widgetRawHtml = (widgetConfig, color) => {
  return `
    <div id="google-review-widget">
      <div data-trustmary-status="active">
        <div id="tm-w486xf">
          <div class="go1305758281 tm-overall-wrapper">
            <a href='https://maps.app.goo.gl/6SSRo6joXD8eJerw9' target='_blank'>
              <div class="go1038471815 tm-overall-stars">
                <div class="go271816224 tm-stars">
                  <div style="width: calc((${widgetConfig.score} * 100%) / 5)"></div>
                </div>

                <b style="color: ${color};">${widgetConfig.score}</b>

                <div
                  aria-label="Google"
                  class="go3304816377 tm-source-logo tm-source-logo--google"
                >
                  <img
                    src="https://d2nce6johdc51d.cloudfront.net/source/google.svg"
                    alt="Google Reviews"
                    class="go1914879238"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  `
}

export const GoogleReviewWidget = ({
  widgetConfig = defaultWidgetConfig,
  color = 'black',
}: {
  widgetConfig: any
  color?: 'black' | 'white'
}): JSX.Element => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: widgetRawHtml(widgetConfig, color) }}
    ></div>
  )
}
