// import { useTranslation } from 'react-i18next'
import { Auth } from '@altalogy/a6y-react-auth'
import { ButtonStyle } from '../UI/Button'
import AuthImage from '../../assets/Auth/Get started-light-1.svg'
import './AuthComponent.css'

/**
 * @typedef IAuth
 *
 * @props {(response: unknown) => void} [onAuthCallback] - on auth api callback.
 * @props {React.ReactNode} [children] -  react node component
 */

interface IAuth {
  children?: React.ReactNode
  onAuthCallback?: (response: unknown) => void
}

/**
 * Renders the auth component.
 *
 * @param {(response: unknown) => void} [onAuthCallback] - on auth api callback.
 * @param {React.ReactNode} [children] - react node component
 *
 * @example
 * <AuthComponent {...params}>
 *  {children}
 * </AuthComponent>
 */

export const AuthComponent = ({
  onAuthCallback,
  children,
}: IAuth): JSX.Element => {
  //   const { t } = useTranslation('auth')
  return (
    <div className='flex max-w-full flex-col md:flex-row'>
      <div className='hidden md:block max-w-full md:max-w-1/2 p-6 sm:py-12 sm:px-12 md:pl-12 md:pr-8 lg:p-16'>
        <div className='flex flex-col items-center px-3 w-400px max-w-full h-full bg-blue-700 border border-blue-600 rounded-xl py-12 mb-12 md:mb-0'>
          <div className='flex flex-grow items-center justify-center'>
            <img className='mx-auto w-56 my-6' src={AuthImage} alt='Welcome' />
          </div>
          {/* <div>
            <div className='flex items-center text-blue-500 m-3'>
              <span className='min-w-1 w-1 bg-blue-600 py-1 h-11 rounded mr-5' />
              <p>{t('AUTH_LABEL_1')}</p>
            </div>
            <div className='flex items-center text-blue-500 m-3'>
              <span className='min-w-1 w-1 bg-blue-600 py-1 h-11 rounded mr-5' />
              <p>{t('AUTH_LABEL_2')}</p>
            </div>
            <div className='flex items-center text-blue-500 m-3'>
              <span className='min-w-1 w-1 bg-blue-500 py-1 h-11 rounded mr-5' />
              <p>
                <b>{t('AUTH_LABEL_3')}</b>
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div className='flex flex-col justify-center max-w-full md:max-w-1/2 p-6 sm:py-12 sm:px-12 md:pl-8 md:pr-12 lg:pt-16'>
        <div className='w-400px max-w-full'>
          {children ? (
            children
          ) : (
            <Auth
              inputStyles='shadow-input outline-none text-sm h-12 w-full border border-blue-500 text-black rounded-md mt-3 mb-3 py-5 px-4 focus:ring-2 focus:ring-blue-500'
              buttonStyles={
                'mx-auto my-8 py-2 px-5 text-sm capitalize ' +
                ButtonStyle.PRIMARY
              }
              className='p-4 py-3'
              onSuccess={resp => onAuthCallback && onAuthCallback(resp)}
              defaultFormState='sign-up'
              labelStyles='capitalize text-blue-500 text-sm font-medium capitalize outline-none'
              consentsHrefStyle='text-blue-500 mx-1'
              consentInputLabelStyle='a6y-react-auth__checkbox-label mx-0 max-h-full'
              consentTextStyle='text-xs'
              consentSpanStyle='a6y-react-auth__checkbox-label__input--checkmark top-0 w-4 h-4 border border-blue-500'
              consentsStyle='font-medium'
              formLinkStyle='flex flex-wrap items-center justify-center text-sm font-semibold'
              linkStyles='mx-1 text-blue-500 font-medium'
              consentsLabelStyle='flex items-center flex-wrap text-xs mt-3'
            />
          )}
        </div>
      </div>
    </div>
  )
}
