import { AuthComponent } from '../../../components/AuthComponent'
import { SignInContainer as A6YSignIn } from '@altalogy/a6y-react-auth'
import { ButtonStyle } from '../../../components/UI/Button'
import UserService from '../../../services/UserService'
import { IAPIResponse } from '../../../services/APIService'
import history from '../../../history'
import { APIError } from '../../../models'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'

interface A6YResponse {
  code?: string
  message?: string
  name?: string
}

export const SignInContainer = ({
  onSuccess,
}: {
  onSuccess?: () => void
}): JSX.Element => {
  const onResponse = async (
    _provider: string,
    username: string,
    response: unknown,
  ): Promise<boolean> => {
    if (
      response &&
      'code' in (response as A6YResponse) &&
      (response as A6YResponse).code === 'NotAuthorizedException' &&
      (response as A6YResponse).message === 'Incorrect username or password.'
    ) {
      // Check if user was migrated
      const result = await UserService.checkIfMigratedUser(username, true)
      if (result instanceof APIError) {
        return false
      }
      const typedResult = (result as unknown) as IAPIResponse<{
        result: string
      }>

      if (typedResult && typedResult.data && typedResult.data.result) {
        if (typedResult.data.result !== 'wrong') {
          history.push(includeCurrentLocaleToPath('/confirm-migration'))
        }
      }
    }
    return false
  }

  return (
    <AuthComponent>
      <A6YSignIn
        onResponse={onResponse}
        onSuccess={onSuccess}
        inputStyles='shadow-input text-sm h-10 w-full border border-blue-500 text-black rounded mt-1 mb-3 p-2'
        buttonStyles={
          'mx-auto my-8 py-3 px-6 shadow text-sm capitalize ' +
          ButtonStyle.PRIMARY
        }
        className='p-4 py-3'
        labelStyles='text-xs font-medium capitalize text-blue-500'
        consentsHrefStyle='text-blue-500 mx-1'
        consentInputLabelStyle='a6y-react-auth__checkbox-label mx-0 max-h-full'
        consentTextStyle='text-xs'
        consentSpanStyle='a6y-react-auth__checkbox-label__input--checkmark top-0 w-4 h-4 border border-blue-500'
        consentsStyle='font-medium'
        formLinkStyle='flex flex-wrap items-center justify-center text-sm font-semibold'
        linkStyles='mx-1 text-blue-500 font-medium'
        consentsLabelStyle='flex items-center flex-wrap text-xs mt-3'
      />
    </AuthComponent>
  )
}
