import { AuthComponent } from '../../../components/AuthComponent'
import {
  SignUpContainer as A6YSignUp,
  AuthService,
} from '@altalogy/a6y-react-auth'
import { ButtonStyle } from '../../../components/UI/Button'

import APIService from '../../../services/APIService'
import history from '../../../history'
import { APIError, User } from '../../../models'
import UserService from '../../../services/UserService'
// import AnalyticsSegmentService from '../../../services/AnalyticsSegmentService'
import { IConsent } from '@altalogy/a6y-react-auth/lib/components/Consents/Consents'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'
import TagManager from 'react-gtm-module'
import * as QueryString from 'query-string'
import { CurrentUserContext } from '../../../App'
import { useContext } from 'react'
import AnalyticsSegmentService from 'src/services/AnalyticsSegmentService'

interface ISignUpContainer {
  onSignUp?: () => void
}

export const SignUpContainer = ({
  onSignUp,
}: ISignUpContainer): JSX.Element => {
  const { setCurrentUser } = useContext(CurrentUserContext)

  const query = QueryString.parse(history.location.search)
  const invitationToken =
    query.invitation_token || localStorage.getItem('invitationToken')

  const onApiSignUp = async (
    email: string,
    password: string,
    consents?: IConsent[],
  ): Promise<{ success: boolean; errorMessage: string }> => {
    const response = await APIService.post('/users/register', null, {
      email,
      password,
      consents,
      segment_anonymous_id: null,
      code: localStorage.getItem('newUserSignUpCode') || null,
      invitation_token: invitationToken,
      sign_up_path: localStorage.getItem('signUpPath'),
    })
    if (response instanceof APIError) {
      if (response.code === 'USER_HAS_TO_BE_MIGRATED') {
        // The user account has to be migrated from the old platform, so
        // redirect user to the migration flow:
        history.push(includeCurrentLocaleToPath('/confirm-migration'))
        return { success: true, errorMessage: response.getMessage() }
      }
      return { success: false, errorMessage: response.getMessage() }
    }

    // Automatically sign in the user for setting aws token
    await AuthService.signIn(email, password)

    const user = new User(UserService.parseAPIResponse(response.data))
    setCurrentUser(user)

    AnalyticsSegmentService.identify(user)

    localStorage.setItem('loggedin', 'yes')

    // Send event to Segment
    // AnalyticsSegmentService.track('Signed Up', {
    //   provider: 'email',
    //   landing_page_type:
    //     window.localStorage.getItem('landingPage') || 'unknown',
    // })
    console.log(
      "localStorage.getItem('signUpPath')",
      localStorage.getItem('signUpPath'),
    )

    const tagManagerArgs =
      localStorage.getItem('signUpPath') == 'survey_js_builder'
        ? {
            dataLayer: {
              event: 'userSignedUpSurveytool',
            },
          }
        : {
            dataLayer: {
              event: 'userSignedUp',
            },
          }

    TagManager.dataLayer(tagManagerArgs)

    // It's neccessary to place after AuthService.signIn(email, password) to have the authentication token
    onSignUp && (await onSignUp())

    // TODO: remove
    await UserService.getUserData()

    return { success: true, errorMessage: '' }
  }

  return (
    <AuthComponent>
      <A6YSignUp
        inputStyles='shadow-input text-sm h-10 w-full border border-blue-500 text-black rounded mt-1 mb-3 p-2'
        buttonStyles={
          'mx-auto my-8 py-3 px-6 shadow text-sm capitalize ' +
          ButtonStyle.PRIMARY
        }
        apiSignUp={onApiSignUp}
        labelStyles='text-xs font-medium capitalize text-blue-500'
        consentsHrefStyle='text-blue-500 mx-1'
        consentInputLabelStyle='a6y-react-auth__checkbox-label mx-0 max-h-full'
        consentTextStyle='text-xs'
        consentSpanStyle='a6y-react-auth__checkbox-label__input--checkmark top-0 w-4 h-4 border border-blue-500'
        consentsStyle='font-medium'
        formLinkStyle='flex flex-wrap items-center justify-center text-sm font-semibold'
        linkStyles='mx-1 text-blue-500 font-medium'
        consentsLabelStyle='flex items-center flex-wrap text-xs mt-3'
      />
    </AuthComponent>
  )
}
