/**
 * Positive NPS score.
 */

export const POSITIVE_NPS_SCORE = 9
export const POSITIVE_REVIEW_RATING = 5
export const NUMBER_SURVEYS_AFTER_WHICH_REVIEW_SHOULD_BE_SEEN = 5
export const CREDITS_GIVEN_FOR_REVIEW = 3
export const GOOGLE_REVIEW_LINK = 'https://g.page/r/CdJ_Gus10gPaEAg/review'
export const TRUSTPILOT_REVIEW_LINK =
  'https://www.trustpilot.com/evaluate/surveyswap.io'
export const FEEDBACKCOMPANY_REVIEW_LINK =
  'https://review.feedbackcompany.com/surveyswap-io-en'

const reviewLinks = [GOOGLE_REVIEW_LINK, TRUSTPILOT_REVIEW_LINK]
export function getReviewLink(): string {
  return reviewLinks[Math.floor(Math.random() * reviewLinks.length)]
}

export const REVIEWS_CONTENT_URL =
  process.env.REACT_APP_REVIEWS_CONFIGURATION_URL

export const fetchReviewsConfiguration = async (url = REVIEWS_CONTENT_URL) => {
  try {
    const response = await fetch(url)
    return await response.json()
  } catch (error) {
    console.log(error)

    throw Error('Error fetching reviews configuration')
  }
}
