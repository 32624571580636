import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Container } from '../ContainerComponent'
import { Link, LinkStyle } from '../Link'
import { Logo } from '../Logo'
import { Button, ButtonStyle } from '../Button'
import { CurrentUserContext } from '../../../App'
import ProfileIcon from '../../../assets/Navbar/person-outline-1.svg'
import ProfileIconWhite from '../../../assets/Navbar/person-outline-1-white.svg'
import arrowWhite from '../../../assets/LandingPage/Home/arrow-white.svg'
import arrowBlack from '../../../assets/LandingPage/Home/arrow-black.svg'
import menuImage from '../../../assets/LandingPage/Home/menu.svg'
import menuWhiteImage from '../../../assets/LandingPage/Home/menu_white.svg'
import { LanguageDropdown } from '../../LanguageDropdown'
import useVariant from '../../../hooks/useVariant'
import { isAGoogleAdVariant } from '../../../config/adVariants'

/**
 * @typedef ILandingNavbarProps
 * @props {string} [className] - the CSS classes.
 * @props {'primary'|'secondary'} [style] - style of nav
 */

export interface ILandingNavbarProps {
  className?: string
  style?: 'primary' | 'secondary'
  getStartedHref?: string
  page?: 'home' | 'paid' | 'students' | 'quote' | 'agency' | 'agency-quote'
}

/**
 * Renders the LandingNavbar component.
 *
 * @param {string} [className] - the CSS classes.
 * @param {'primary'|'secondary'} [style] - style of nav
 * @example
 *  <LandingNavbar />
 */

export const LandingNavbar: React.FunctionComponent<ILandingNavbarProps> = ({
  className = 'flex items-center justify-between w-full bg-white z-50 transition-all h-36',
  style = 'primary',
  getStartedHref = '/sign-up',
  page = 'home',
}: ILandingNavbarProps) => {
  const { t } = useTranslation(['auth', 'nav'])
  const [scrolled, setScrolled] = useState(false)
  const [menu, setMenu] = useState(false)
  const { inUse, vt } = useVariant()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 100) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  function urlActive(pathname: string): boolean {
    const noLangPathname = window.location.pathname
      .replace('/de/', '/')
      .replace('/nl/', '/')
    return pathname === noLangPathname
  }

  let CTA_TEXT = 'app:GET_STARTED_HOME'
  switch (page) {
    case 'paid':
      CTA_TEXT = 'app:GET_STARTED_PAID'
      break
    case 'quote':
      CTA_TEXT = 'app:GET_STARTED_QUOTE'
      break
    case 'agency':
      CTA_TEXT = 'app:GET_STARTED_AGENCY'
      break
    case 'agency-quote':
      CTA_TEXT = 'app:GET_STARTED_AGENCY_QUOTE'
      break
    case 'students':
      CTA_TEXT = inUse ? vt('GET_STARTED_CTA') : 'app:GET_STARTED_FREE'
      break
    default:
      CTA_TEXT = 'app:GET_STARTED_HOME'
      break
  }

  // Adjust the getStartedHref based on the page
  if (page === 'quote') {
    getStartedHref = '#aboveQuote'
  }
  return (
    <CurrentUserContext.Consumer>
      {({ currentUser }) => (
        <>
          <nav
            className={classNames(className, {
              [' bg-blue-500 text-white']: style === 'secondary',
            })}
          >
            <Container className='pr-0 pl-0 flex items-center sm:max-w-screen-lg justify-between w-full mx-auto'>
              <div className='flex items-center'>
                <Link href='/' style={LinkStyle.NONE}>
                  <Logo
                    signetType={style}
                    logotypeClassName='hidden sm:block'
                    className='flex items-center sm:w-36 justify-between p-1 sm:p-2 m-1'
                  />
                </Link>
                {!isAGoogleAdVariant(window.location.pathname) && (
                  <>
                    <Link
                      className='ml-6 mr-4 hidden md:block'
                      style={classNames(LinkStyle.NONE, {
                        ['font-bold']: urlActive('/students'),
                      })}
                      href='/students'
                    >
                      {t('nav:FOR_STUDENTS')}
                    </Link>
                    <Link
                      className='mr-4 hidden md:block'
                      style={classNames(LinkStyle.NONE, {
                        ['font-bold']: urlActive('/agency'),
                      })}
                      href='/agency'
                    >
                      {t('nav:FOR_BUSINESS')}
                    </Link>
                    {/* <Link
                      className='mr-4 hidden md:block'
                      style={classNames(LinkStyle.NONE, {
                        ['font-bold']: urlActive('/quote'),
                      })}
                      href='/quote'
                    >
                      {t('nav:FOR_ENTERPRISE')}
                    </Link> */}
                    {/* <Link
                      className='mr-4 hidden md:block'
                      style={classNames(LinkStyle.NONE, {
                        ['font-bold']: urlActive('/agency'),
                      })}
                      href='/quote'
                    >
                      {t('nav:FOR_AGENCY')}
                    </Link> */}
                  </>
                )}
              </div>
              <div className='flex items-center justify-end flex-1'>
                {currentUser ? (
                  <Button
                    style={classNames(
                      'flex items-center justify-center rounded-md',
                      {
                        ['bg-blue-400']: style === 'secondary',
                        ['border bg-gray-700 text-black border-gray-600']:
                          style === 'primary',
                      },
                    )}
                    className={`ml-4 my-2 mr-2 pl-2 md:pl-4 pr-2 py-2 text-sm`}
                    iconPosition='right'
                    type='button'
                    href='/surveys'
                    icon={
                      style === 'secondary' ? ProfileIconWhite : ProfileIcon
                    }
                  >
                    <p className='md:flex font-medium whitespace-nowrap bg-blue'>
                      {t('nav:DASHBOARD')}
                    </p>
                  </Button>
                ) : (
                  <Button
                    style={classNames(
                      'flex items-center justify-center rounded-md',
                      {
                        ['bg-blue-400']: style === 'secondary',
                        ['border bg-gray-700 text-black border-gray-600']:
                          style === 'primary',
                      },
                    )}
                    className={`ml-5 my-2 mr-2 pl-2 md:px-4 pr-2 py-2 text-sm hidden md:flex`}
                    type='button'
                    href='/sign-up'
                  >
                    <p className='flex font-medium whitespace-nowrap'>
                      {t('nav:SIGN_UP')}
                    </p>
                  </Button>
                )}
                {!isAGoogleAdVariant(window.location.pathname) && !inUse && (
                  <div className='ml-4 mr-2'>
                    <LanguageDropdown style={style} />
                  </div>
                )}

                {!currentUser && (
                  <Button
                    style={classNames({
                      [ButtonStyle.BLUE]: style === 'primary',
                      ['flex items-center justify-center rounded-md bg-white text-black']:
                        style === 'secondary',
                    })}
                    className={`min-w-32 ml-4 my-2 mr-2 pl-2 md:px-4 pr-2 py-2 text-sm hidden xs:flex`}
                    type='button'
                    href={getStartedHref}
                  >
                    <p className='flex font-medium whitespace-nowrap'>
                      {t(CTA_TEXT)}

                      <img
                        className='ml-2'
                        src={style === 'secondary' ? arrowBlack : arrowWhite}
                        alt='start'
                      />
                    </p>
                  </Button>
                )}

                <Button
                  style=''
                  className={classNames(
                    'min-w-8 ml-5 my-2 mr-2 pl-2 md:px-4 pr-2 py-2 text-sm md:hidden xs:flex bg-transparent',
                    { ['fixed z-50']: menu },
                  )}
                  type='button'
                  onClick={() => setMenu(!menu)}
                >
                  {urlActive('/paid') ? (
                    <img className='ml-2' src={menuWhiteImage} alt='menu' />
                  ) : (
                    <img className='ml-2' src={menuImage} alt='menu' />
                  )}
                </Button>
              </div>
              <div
                className={classNames(
                  'fixed z-40 px-6 py-16 left-0 top-0 min-w-screen min-h-screen transform md:hidden flex flex-col',
                  {
                    ['translate-x-full']: !menu,
                    ['translate-x-0']: menu,
                    ['bg-blue-500']: urlActive('/paid'),
                    ['bg-white']: !urlActive('/paid'),
                  },
                )}
              >
                <Link
                  className='ml-12 mr-4 text-2xl'
                  style={classNames(LinkStyle.NONE, {
                    ['font-bold']: urlActive('/students'),
                  })}
                  href='/students'
                >
                  {t('nav:FOR_STUDENTS')}
                </Link>
                <Link
                  className='ml-12 mt-8 mr-4 text-2xl'
                  style={classNames(LinkStyle.NONE, {
                    ['font-bold']: urlActive('/paid'),
                  })}
                  href='/agency'
                >
                  {t('nav:FOR_BUSINESS')}
                </Link>
                <Link
                  className='ml-12 mt-8 mr-4 text-2xl'
                  style={classNames(LinkStyle.NONE, {
                    ['font-bold']: urlActive('/agency'),
                  })}
                  href='/agency'
                >
                  {t('nav:FOR_AGENCY')}
                </Link>
              </div>
            </Container>
          </nav>
          <nav
            className={classNames(
              'items-center justify-between w-full bg-white z-50 transition-all min-h-16',
              {
                ['flex fixed top-0 shadow']: scrolled,
                ['hidden']: !scrolled,
              },
            )}
          >
            <Container className='max-w-screen-lg mx-auto px-8 lg:px-0 flex items-center justify-end w-full'>
              <Button
                style={ButtonStyle.BLUE}
                className={`my-2 mx-0 px-3 md:px-5 py-3 text-sm hidden xs:flex`}
                type='button'
                href={getStartedHref}
              >
                <p className='flex font-medium whitespace-nowrap'>
                  {t(CTA_TEXT)}

                  <img className='ml-2' src={arrowWhite} alt='start' />
                </p>
              </Button>
            </Container>
          </nav>
        </>
      )}
    </CurrentUserContext.Consumer>
  )
}
