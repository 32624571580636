export const emptyPlan = new Plan({
  plan_name: 'other',
  credits: 0,
  price_eur: 0,
  price_usd: 0,
  price_gbp: 0,
  discount_rate: 0,
  discount_eur: 0,
  discount_usd: 0,
  discount_gbp: 0,
  total_usd: 0,
  total_eur: 0,
  total_gbp: 0,
})

import React, { useState, useEffect, useRef, useContext } from 'react'
import { APIError, Plan, Survey, TCurrencies } from '../../models'
import { FaChevronDown } from 'react-icons/fa'
import { round } from 'lodash'
import { CurrentUserContext } from 'src/App'
import SurveyService from 'src/services/SurveyService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Loader } from 'src/components/UI/Loader'
import { Input } from 'src/components/UI/Inputs'
import OrdersService from 'src/services/OrdersService'
import { IPackage } from 'src/components/BuyCredits/BuyCreditsComponent'
import { humanizePrice, priceWithCurrency } from 'src/helpers/formattingHelpers'
import AnalyticsSegmentService from 'src/services/AnalyticsSegmentService'
import TagManager from 'react-gtm-module'
import CurrencySelect from 'src/components/BuyCredits/components/CurrencySelect'
import Statistics from 'src/components/BuyCredits/components/Statistics'
import { Modal } from 'src/components/UI/Modal'
import { SignUpContainer, SignInContainer } from '../Auth'

import A6YReactAuth from '@altalogy/a6y-react-auth'

import { initMiniSignInSignUpBuyCredits } from 'src/config/amplify-auth'
import { cloneDeep } from 'lodash'
import history from 'src/history'

const BuyCreditsContainer = () => {
  const [billingCycle, setBillingCycle] = useState('monthly')
  const [selectedSurvey, setSelectedSurvey] = useState<Survey>(
    new Survey({ duration: 5, respondents: 100 }),
  )
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [mySurveys, setMySurveys] = useState<Survey[]>([])
  const [currency, setCurrency] = useState<TCurrencies>('EUR')
  const [loading, setLoading] = useState<boolean>(true)

  const [surveyDuration, setSurveyDuration] = useState('5')
  const [respondentsCount, setRespondentsCount] = useState('100')
  const { t } = useTranslation('app')
  const { currentUser } = useContext(CurrentUserContext)

  const [displayingSignUp, setDisplayingSignUp] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)

  const showSignInContainer =
    new URLSearchParams(window.location.search).get('auth') === 'sign_in'

  const planAttrs = JSON.parse(localStorage.getItem('selectedPlan'))

  const queryParams = new URLSearchParams(window.location.search)
  const shouldProceedToMollie = !!queryParams.get('purchasing') && planAttrs
  const [isOrdering, setIsOrdering] = useState<boolean>(shouldProceedToMollie)

  const proceedToMollie = async plan => {
    localStorage.setItem('noRedirect', 'true')

    await onBuy(plan)
  }

  useEffect(() => {
    // HACK: prevent redirecting to /surveys after social sign up
    localStorage.setItem('noRedirect', 'true')

    return () => {
      localStorage.removeItem('noRedirect')
    }
  })

  // Handler ordering after sign up social
  useEffect(() => {
    if (currentUser && shouldProceedToMollie) {
      const plan = new Plan(planAttrs)
      proceedToMollie(plan)

      localStorage.removeItem('selectedPlan')
      queryParams.delete('purchasing')
      history.replace({ search: queryParams.toString() })
    }
  }, [currentUser])

  useEffect(() => {
    const oldConfig = cloneDeep(globalThis.A6YReactAuthConfig)

    initMiniSignInSignUpBuyCredits()

    return () => {
      new A6YReactAuth().initialize(oldConfig)
    }
  }, [])

  useEffect(() => {
    getMySurveys()
  }, [currentUser])

  const getMySurveys = async () => {
    if (isOrdering || !currentUser) {
      setLoading(false)

      return
    }

    setLoading(true)

    try {
      const response = await SurveyService.getMySurveys()
      if (response instanceof APIError) {
        toast.error(t('errors.something_went_wrong'))
        return
      }

      const surveys = response.filter(survey => survey.status !== 'draft')
      setMySurveys(surveys)

      // Only set default survey if we don't have one selected yet
      if (surveys && surveys.length > 0 && !selectedSurvey.id) {
        // Find active surveys
        const activeSurveys = surveys.filter(
          survey => survey.status === 'active',
        )

        // Find insufficient credits surveys
        const insufficientSurveys = surveys.filter(
          survey => survey.status === 'insufficient_credits',
        )

        let selectedDefaultSurvey

        if (activeSurveys.length > 0) {
          // If we have active surveys, take the newest one
          selectedDefaultSurvey = activeSurveys.reduce((newest, current) => {
            return new Date(current.created_at) > new Date(newest.created_at)
              ? current
              : newest
          }, activeSurveys[0])
        } else if (insufficientSurveys.length > 0) {
          // If we have insufficient credits surveys, take the newest one
          selectedDefaultSurvey = insufficientSurveys.reduce(
            (newest, current) => {
              return new Date(current.created_at) > new Date(newest.created_at)
                ? current
                : newest
            },
            insufficientSurveys[0],
          )
        } else {
          // If no active or insufficient credit surveys, take the newest overall
          selectedDefaultSurvey = surveys.reduce((newest, current) => {
            return new Date(current.created_at) > new Date(newest.created_at)
              ? current
              : newest
          }, surveys[0])
        }

        setSelectedSurvey(selectedDefaultSurvey)
      }
    } catch (error) {
      toast.error(t('errors.something_went_wrong'))
    } finally {
      setLoading(false)
    }
  }

  const openSignUpModal = plan => {
    localStorage.setItem('after_login', window.location.href)

    setSelectedPlan(plan)

    // For social sign up/sign in
    localStorage.setItem('selectedPlan', JSON.stringify(plan))

    setDisplayingSignUp(true)
  }

  const onBuy = async plan => {
    setIsOrdering(true)

    // Track with the Segement an attempt of buying credits
    AnalyticsSegmentService.track('Buying Credits', {
      credits: plan.credits,
      currency: currency,
      plan: plan.plan_name,
    })

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })

    const response = await OrdersService.buy(plan, currency)

    if (response instanceof APIError) {
      setIsOrdering(false)

      toast.error(
        'Something went wrong, please contact customer support from the widget on the bottom right',
      )

      return
    }

    localStorage.setItem('after_login', window.location.href)

    if (response && response.checkoutUrl) {
      window.location.replace(response.checkoutUrl)
    }
  }

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow positive numbers
    const value = e.target.value.replace(/[^0-9]/g, '')
    setSurveyDuration(value)
  }

  const handleRespondentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow positive numbers
    const value = e.target.value.replace(/[^0-9]/g, '')
    setRespondentsCount(value)
  }

  const [plans, setPlans] = useState<Plan[]>([])

  const buildPackages = (): IPackage[] => {
    if (selectedSurvey.getRequiredCredits() === 0) {
      const emptyPackages = [{}, {}, {}] as IPackage[]

      return emptyPackages
    }

    return [
      {
        name: 'Try it out',
        duration: selectedSurvey.duration,
        respondents: 5,
      },
      {
        name: 'Complete your study',
        duration: selectedSurvey.duration,
        respondents: selectedSurvey.respondents, // This is already 100% of selectedSurvey.respondents
      },
      {
        name: 'Accelerator',
        duration: selectedSurvey.duration,
        respondents: Math.round(selectedSurvey.respondents * 0.5),
      },
    ]
  }

  useEffect(() => {
    fetchPlans()
  }, [selectedSurvey.getRequiredCredits()])

  const fetchPlans = async (discountCode?: string) => {
    if (isOrdering) {
      return
    }

    const requiredCredits = planPackage =>
      planPackage.duration * planPackage.respondents

    const validPackages = buildPackages().filter(planPackage =>
      requiredCredits(planPackage),
    )

    const APICalls = validPackages.map(planPackage => {
      return OrdersService.calculatePlan({
        credits: requiredCredits(planPackage),
        currency: currency,
        price: null,
        discountCode, // Pass the discount code, if provided
      })
    })

    const handleResponse = response => {
      if (response instanceof APIError) {
        console.error('ERROR: cannot calculate other plan with API', response)
        return null
      }

      return response
    }

    const responses = await Promise.all(APICalls)
    const filteredPlans = responses.map(handleResponse).filter(plan => plan)

    filteredPlans.forEach((plan: Plan, idx) => {
      const planPackage = validPackages[idx]
      const percentage =
        round(planPackage.respondents / selectedSurvey.respondents, 1) * 100

      plan.plan_name = planPackage.name
      plan.respondents = planPackage.respondents
      plan.respondentsPercentage = percentage >= 100 ? 100 : percentage

      plan.isPopular = plan.plan_name === 'Complete your study'
    })

    setPlans(filteredPlans)
  }

  const updatePlanFromUserInput = () => {
    toast.info('Updating price calculation...')

    // Create a new Survey instance with the input values
    const newSurvey = new Survey({
      duration: parseInt(surveyDuration) || 1,
      respondents: parseInt(respondentsCount) || 1,
    })

    // Update the selected survey with new values
    setSelectedSurvey(newSurvey)

    // This will trigger the useEffect that calls fetchPlans() since it depends on selectedSurvey
    // No need to call fetchPlans() directly here since the useEffect will handle it
  }

  const filteredSurveys = mySurveys?.filter(survey =>
    survey.getRequiredCredits(),
  )

  const userSurveys = [
    'UX Research Study 2024',
    'Customer Satisfaction Survey',
    'Product Feedback Collection',
    'Academic Research Project',
  ]

  const planDetails = [
    {
      name: 'Try it out',
      description: 'Test the waters with a small purchase',
      price: 0,
      karma: 500,
      emoji: '👋',
      features: [
        'Enough karma for 5 respondents',
        'Slight boost to your position in the ranking',
        'Money back guarantee',
      ],
      buttonClass: 'bg-gray-500 hover:bg-gray-400 focus:ring-gray-400',
    },
    {
      name: 'Complete your study',
      description: 'Get enough karma to get all the respondents you need',
      price: 99,
      originalPrice: 149,
      karma: 10000,
      emoji: '🚀',
      popular: true,
      features: [
        'Enough karma for your whole data collection',
        'Big boost to your rank position',
        'Money back guarantee',
      ],
      buttonClass:
        'bg-blue-400 hover:bg-blue-500 focus:ring-blue-500 text-white',
    },
    {
      name: 'Accelerator',
      description: 'Get half of the respondents you need and save time!',
      price: 49,
      originalPrice: 79,
      karma: 5000,
      emoji: '💪',
      features: [
        'Enough karma for your 50% of the respondents',
        'Boost to your position in the ranking',
        'Money back guarantee',
      ],
      buttonClass: 'bg-gray-500 hover:bg-gray-400 focus:ring-gray-400',
    },
  ]

  if (isOrdering || loading) {
    return (
      <div className='pt-10 sm:h-80 flex items-center justify-center'>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <Modal
        modalWithButton={false}
        modalOpen={displayingSignUp}
        modalHandler={() => setDisplayingSignUp(false)}
        show={displayingSignUp}
        modalWidth='780'
        modalHeight='none'
      >
        {showSignInContainer ? (
          <SignInContainer onSuccess={() => proceedToMollie(selectedPlan)} />
        ) : (
          <SignUpContainer onSignUp={() => proceedToMollie(selectedPlan)} />
        )}
      </Modal>

      <section className='py-12 bg-white sm:py-16 lg:py-10 xl:py-16'>
        <div className='px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl'>
          <div className='max-w-3xl mx-auto text-center'>
            <h2 className='text-3xl font-semibold tracking-tight sm:text-4xl lg:text-5xl'>
              Buy respondents for your survey
              {/* Get Respondents Faster then Ever */}
            </h2>
            <p className='mt-4 text-base font-normal leading-7 lg:text-lg lg:mt-6 lg:leading-8'>
              Buy karma points to get a higher ranking on our survey list with
              tens of thousands of real respondents 🚀
            </p>
          </div>

          <div className='max-w-3xl mx-auto text-center mt-10'>
            {mySurveys.length > 0 ? (
              <div className='relative inline-block text-left'>
                <div className='flex items-center justify-center space-x-2'>
                  <span className='text-md'>Showing prices for:</span>
                  <button
                    type='button'
                    className='inline-flex items-center px-3 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-50'
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <span className='text-blue-500'>
                      {selectedSurvey.title}
                    </span>
                    <FaChevronDown className='w-4 h-4 ml-2' />
                  </button>
                </div>

                {isDropdownOpen && (
                  <div className='absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg border border-gray-200'>
                    <div className='py-1'>
                      {mySurveys.map(survey => (
                        <button
                          key={survey.id}
                          className='block w-full px-4 py-2 text-sm text-left hover:bg-gray-50'
                          onClick={() => {
                            setSelectedSurvey(survey)
                            setIsDropdownOpen(false)
                          }}
                        >
                          {survey.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='flex items-center flex-col md:flex-row justify-center space-x-2 p-4 bg-blue-50 rounded-lg border border-blue-100'>
                <span className='text-md font-medium mb-3 md:mb-0'>
                  Calculate prices for a
                </span>
                <Input
                  id='survey-duration'
                  typeInput='number'
                  value={surveyDuration}
                  onChange={handleDurationChange}
                  className='w-16 text-center bg-white'
                  inputStyles='text-center text-blue-400'
                  min={1}
                  max={999}
                />
                <span className='text-md font-medium mb-3 md:mb-0'>
                  minute survey with
                </span>
                <Input
                  id='respondents-count'
                  typeInput='number'
                  value={respondentsCount}
                  onChange={handleRespondentsChange}
                  className='w-20 text-center bg-white'
                  inputStyles='text-center text-blue-400'
                  min={1}
                  max={9999}
                />
                <span className='text-md font-medium mb-3 md:mb-0'>
                  participants
                </span>
                <button
                  onClick={updatePlanFromUserInput}
                  className='ml-2 px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-700 transition-colors font-medium'
                >
                  Update Prices
                </button>
              </div>
            )}
          </div>

          <div className='relative grid max-w-5xl grid-cols-1 mx-auto lg:grid-cols-3 lg:gap-8 md:mt-20'>
            <div className='md:absolute mb-6 -top-16 right-0 flex justify-end z-10'>
              <CurrencySelect
                currencyOptions={['USD', 'EUR', 'GBP']}
                changeCurrency={setCurrency}
                currency={currency}
              />{' '}
            </div>
            {plans.map((plan, index) => {
              const planDetail = planDetails.find(
                pd => pd.name === plan.plan_name,
              )
              return (
                <div
                  key={planDetail.name}
                  className={`relative bg-white border border-gray-200 divide-y divide-gray-200 rounded-2xl sm:rounded-3xl ${
                    index === 1
                      ? 'mt-14 lg:mt-0'
                      : index === 2
                      ? 'mt-6 lg:mt-0'
                      : ''
                  }`}
                >
                  {plan.isPopular && (
                    <div className='absolute -top-6 right-0 flex items-start -mt-8'>
                      <svg
                        className='w-auto h-16 text-blue-400'
                        viewBox='0 0 83 64'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M4.27758 62.7565C4.52847 63.5461 5.37189 63.9827 6.16141 63.7318L19.0274 59.6434C19.817 59.3925 20.2536 58.5491 20.0027 57.7595C19.7518 56.97 18.9084 56.5334 18.1189 56.7842L6.68242 60.4184L3.04824 48.982C2.79735 48.1924 1.95394 47.7558 1.16441 48.0067C0.374889 48.2576 -0.0617613 49.101 0.189127 49.8905L4.27758 62.7565ZM13.4871 47.8215L12.229 47.0047L13.4871 47.8215ZM39.0978 20.5925L38.1792 19.4067L39.0978 20.5925ZM7.03921 62.9919C8.03518 61.0681 13.1417 51.1083 14.7453 48.6383L12.229 47.0047C10.5197 49.6376 5.30689 59.8127 4.37507 61.6126L7.03921 62.9919ZM14.7453 48.6383C22.0755 37.3475 29.8244 29.6738 40.0164 21.7784L38.1792 19.4067C27.7862 27.4579 19.7827 35.3698 12.229 47.0047L14.7453 48.6383ZM40.0164 21.7784C52.6582 11.9851 67.634 7.57932 82.2576 3.44342L81.4412 0.556653C66.8756 4.67614 51.3456 9.20709 38.1792 19.4067L40.0164 21.7784Z' />
                      </svg>
                      <span className='ml-2 -mt-2 text-sm font-semibold text-blue-400'>
                        Most popular
                      </span>
                    </div>
                  )}

                  <div className='px-4 py-5 bg-gray-500 sm:p-6 rounded-t-2xl sm:rounded-t-3xl'>
                    <div className='flex items-start'>
                      <span className='text-3xl shrink-0'>
                        {planDetail.emoji}
                      </span>
                      <div className='ml-6'>
                        <h3 className='text-lg font-semibold'>
                          {planDetail.name}
                        </h3>
                        <p className='mt-2 text-sm font-normal'>
                          {planDetail.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='px-4 py-5 sm:p-6'>
                    <div className='flex justify-between items-start'>
                      {/* Left side - Respondents */}
                      <div className='flex flex-col'>
                        <span className='text-4xl font-bold'>
                          {plan.respondents}
                        </span>
                        <span className=''>respondents</span>
                        <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'>
                          {plan.credits.toLocaleString()} karma points
                        </span>
                      </div>

                      {/* Right side - Price, discount, and karma */}
                      <div className='flex flex-col items-end space-y-2'>
                        <div className='flex flex-col items-end'>
                          <div className='flex flex-col items-end'>
                            {plan.discount_rate > 0 && (
                              <>
                                <span className='text-md font-bold text-green-400'>
                                  Save{' '}
                                  {priceWithCurrency(
                                    humanizePrice(
                                      plan.getDiscountInCurrency(currency),
                                      currency,
                                    ),
                                    currency,
                                  )}
                                </span>
                                <span className='text-sm line-through'>
                                  {priceWithCurrency(
                                    humanizePrice(
                                      plan.getPriceInCurrency(currency),
                                      currency,
                                    ),
                                    currency,
                                  )}
                                </span>
                              </>
                            )}
                            <p className='text-3xl font-semibold'>
                              {priceWithCurrency(
                                humanizePrice(
                                  plan.getTotalInCurrency(currency),
                                  currency,
                                ),
                                currency,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-6'>
                      <a
                        href='#'
                        className={`inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium transition-all duration-200 border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 ${planDetail.buttonClass}`}
                        role='button'
                        onClick={() => {
                          currentUser ? onBuy(plan) : openSignUpModal(plan)
                        }}
                      >
                        Buy now
                      </a>
                    </div>

                    <ul className='mt-8 space-y-4'>
                      {planDetail.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className='flex items-start'>
                          {' '}
                          {/* Changed to items-start */}
                          <svg
                            className='w-5 h-5 mr-2 text-blue-400 shrink-0 mt-0.5' /* Added mt-0.5 for slight top margin */
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 20 20'
                            fill='currentColor'
                          >
                            <path
                              fillRule='evenodd'
                              d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                              clipRule='evenodd'
                            />
                          </svg>
                          <span className='flex-1'>{feature}</span>{' '}
                          {/* Wrapped text in span with flex-1 */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Statistics></Statistics>
    </>
  )
}
export default BuyCreditsContainer
