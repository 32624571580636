// my-profile.tsx
/* eslint-disable react/no-unescaped-entities */

import {
  getCountriesOptions,
  getLanguagesOptions,
} from '../../../helpers/languagesHelpers'
import { getEducationOptions } from '../../../config/educations'
import { getOccupationOptions } from '../../../config/occupations'
import i18next from '../../../I18n'
import { Survey } from '../../../models'
import {
  IntroductionPage,
  GetToKnowYouBetter,
  GreatJobSoFar,
  HowItWorks,
  KarmaIntro,
  ReferralKarma,
  TakeSurveys,
  BuyKarma,
  QualityControl,
  QualityFeedback,
  GrowTogether,
  SurveySwapLimitations,
  WorkIsRewarded,
} from './my-profile-components'
import {
  renderReactToHtml,
  wrapInSurveyElement,
  wrapInSurveyElementWithoutFrame,
} from './survey-renderer'
import curiousCat from '../../../assets/Cats/curious-cat3.svg'
import ReactDOMServer from 'react-dom/server'

export const WELCOME_SURVEY_CODE = 'WELCOME_SURVEY'

const surveyJSOptionAdapter = (option: any) => ({
  value: option.value,
  text: option.label,
})

const translation = (i18nKey: string) => i18next.t(i18nKey, { ns: 'app' })

const countriesOptions = getCountriesOptions(translation).map(
  surveyJSOptionAdapter,
)
const languagesOptions = getLanguagesOptions(translation).map(
  surveyJSOptionAdapter,
)
const gendersOptions = [
  { value: 'female', text: translation('FEMALE') },
  { value: 'male', text: translation('MALE') },
  { value: 'other_gender', text: translation('OTHER_GENDER') },
]
const educationOptions = getEducationOptions(translation).map(
  surveyJSOptionAdapter,
)
const occupationOptions = getOccupationOptions(translation).map(
  surveyJSOptionAdapter,
)

const surveyswapSurvey = new Survey({
  id: 'my-profile',
  code: WELCOME_SURVEY_CODE,
})

// Pre-render React components to HTML
const introHtml = renderReactToHtml(IntroductionPage)
const howItWorksHtml = renderReactToHtml(HowItWorks)
const SurveySwapLimitationsHtml = renderReactToHtml(SurveySwapLimitations)
const getToKnowYouHtml = renderReactToHtml(GetToKnowYouBetter)
const greatJobHtml = renderReactToHtml(GreatJobSoFar)
const KarmaIntroHtml = renderReactToHtml(KarmaIntro)
const referralKarmaHtml = renderReactToHtml(ReferralKarma)
const buyKarmaHtml = renderReactToHtml(BuyKarma)
const takeSurveysHtml = renderReactToHtml(TakeSurveys)
const qualityControlHtml = renderReactToHtml(QualityControl)
const qualityFeedbackHtml = renderReactToHtml(QualityFeedback)
const growTogetherHtml = renderReactToHtml(GrowTogether)
const WorkIsRewardedHtml = renderReactToHtml(WorkIsRewarded)

export default {
  completedHtml: ReactDOMServer.renderToStaticMarkup(
    <div className='max-w-[95%] mx-auto whitespace-normal'>
      <div className='bg-white p-6 rounded-lg shadow-md'>
        <div className='mt-4 space-y-6'>
          {/* Congratulations Message */}
          <h1 className='font-bold text-xl text-center mb-4'>
            Congratulations, you've finished your first survey!! 🎉
          </h1>

          {/* Centered GIF */}
          <div className='flex justify-center'>
            <img
              src='https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExcmtub2poMnFmdHR1dnQ5c2ZpbnY4N2M2OG05djNrbWVrYW1lNjkzYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9dg/702ybfQFkrkrWnIByR/giphy.gif'
              alt='Celebratory GIF'
              className='w-1/2 md:w-1/3 rounded-md'
            />
          </div>

          {/* Credits Link */}
          <div className='text-center space-y-4'>
            <p className='font-normal text-base'>
              To get your Karma, go to{' '}
              <a
                href={`${surveyswapSurvey.getSurveyCodeLink()}`}
                className='underline text-blue-500'
              >
                {surveyswapSurvey.getSurveyCodeLink()}
              </a>
            </p>
            <p className='font-normal text-base'>
              Alternatively, you can enter this code manually:{' '}
              <strong>WELCOME_SURVEY</strong>
            </p>
            <p className='font-normal text-base'>
              PSS. This survey was created by our own Survey Builder tool. It’s
              totally free to use!
            </p>
          </div>
        </div>
      </div>
    </div>,
  ),
  showQuestionNumbers: 'off',
  pages: [
    {
      elements: [
        {
          type: 'html',
          name: 'intro',
          html: wrapInSurveyElementWithoutFrame(introHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'intro2',
          html: wrapInSurveyElementWithoutFrame(howItWorksHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'referral_Karma',
          html: wrapInSurveyElementWithoutFrame(takeSurveysHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'referral_Karma',
          html: wrapInSurveyElementWithoutFrame(referralKarmaHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'purchase_Karma',
          html: wrapInSurveyElementWithoutFrame(buyKarmaHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'work_rewarded',
          html: wrapInSurveyElementWithoutFrame(WorkIsRewardedHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'platform_limitations',
          html: wrapInSurveyElementWithoutFrame(SurveySwapLimitationsHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'getToKnowYou',
          html: wrapInSurveyElementWithoutFrame(getToKnowYouHtml),
        },
      ],
    },
    {
      elements: [
        // {
        //   type: 'html',
        //   name: 'profile_image',
        //   html: `
        //       <div class='flex justify-center items-center'>
        //         <img src=${curiousCat} alt='Cute animal' class='w-80 h-auto rounded-lg' />
        //       </div>
        //     `,
        // },
        {
          name: 'first_name',
          type: 'text',
          title: 'First name',
          isRequired: true,
        },
        {
          name: 'last_name',
          type: 'text',
          title: 'Last name',
          isRequired: false,
        },
        {
          name: 'gender',
          type: 'dropdown',
          title: 'Gender',
          choices: gendersOptions,
          isRequired: true,
        },
        {
          name: 'date_of_birth',
          type: 'text',
          inputType: 'date',
          title: 'Your date of birth',
          isRequired: true,
          placeholder: 'dd/mm/yyyy',
        },
      ],
    },

    // Page 2: Avatar Selection
    {
      elements: [
        {
          type: 'html',
          name: 'avatar_intro',
          html: `
          <div class="text-center mb-4">
            <h4 class="font-semibold text-lg mb-2">Choose your avatar! 🎨</h4>
            <p class="">Pick an avatar that represents you in the SurveySwap community</p>
          </div>
        `,
        },
        {
          name: 'avatar_choice',
          type: 'imagepicker', // Using 'imagepicker' for visual selection
          titleLocation: 'hidden',
          isRequired: true,
          imageWidth: 100,
          imageHeight: 100,
          colCount: 3, // Display images in 3 columns
          choices: [
            {
              value: 'boy_avatar_1',
              imageLink: '/avatars/boy-avatar.png',
            },
            {
              value: 'boy_avatar_2',
              imageLink: '/avatars/boy-avatar2.png',
            },
            {
              value: 'girl_avatar_1',
              imageLink: '/avatars/girl.png',
            },
            {
              value: 'girl_avatar_2',
              imageLink: '/avatars/girl-avatar2.png',
            },
            {
              value: 'bulb_idea',
              imageLink: '/avatars/bulb-idea.png',
            },
            {
              value: 'prize_cup',
              imageLink: '/avatars/prize-cup.png',
            },
          ],
          showLabel: false,
        },
      ],
    },

    // Page 3: Additional Info
    {
      elements: [
        {
          name: 'country_of_residence',
          type: 'dropdown',
          title: 'Current Country of residence',
          choices: countriesOptions,
          isRequired: true,
        },
        {
          name: 'languages',
          type: 'checkbox',
          title: 'What languages do you speak?',
          description:
            "We'll use this to show you the surveys relevant for you",
          descriptionLocation: 'underTitle',
          choices: languagesOptions,
          isRequired: true,
        },
      ],
    },
    {
      elements: [
        {
          name: 'occupation',
          type: 'dropdown',
          title: 'What is your current occupation?',
          choices: occupationOptions,
          isRequired: true,
        },
        {
          name: 'finished_education',
          type: 'dropdown',
          title: 'Finished Education',
          choices: educationOptions,
          isRequired: true,
        },
      ],
    },
    {
      elements: [
        {
          type: 'text',
          name: 'university',
          title: 'What University do you go to?',
          isRequired: false,
          visibleIf: "{occupation} == 'student'",
        },
        {
          type: 'text',
          name: 'major',
          title: "What's your major?",
          isRequired: false,
          visibleIf: "{occupation} == 'student'",
        },
        {
          type: 'dropdown',
          name: 'study_year',
          title: 'What year are you in?',
          choices: [
            '1st Year',
            '2nd Year',
            '3rd Year',
            '4th Year',
            '5th Year or above',
          ],
          isRequired: false,
          visibleIf: "{occupation} == 'student'",
        },
        {
          type: 'dropdown',
          name: 'study_type',
          title: 'What type of study are you doing?',
          choices: ['Bachelor thesis', 'Master thesis', 'PhD', 'Other'],
          isRequired: false,
          visibleIf: "{occupation} == 'student'",
        },
      ],
      visibleIf: "{occupation} == 'student'",
    },
    {
      elements: [
        {
          name: 'company_name',
          type: 'text',
          title: 'What is the name of your company?',
          isRequired: false,
          visibleIf:
            "{occupation} == 'full_time' || {occupation} == 'part_time'",
        },
        {
          name: 'job_title',
          type: 'text',
          title: 'What is your job title?',
          isRequired: false,
          visibleIf:
            "{occupation} == 'full_time' || {occupation} == 'part_time'",
        },
        {
          type: 'dropdown',
          name: 'responsibility_level',
          title: 'What is your level of responsibility?',
          choices: [
            'Entry-Level',
            'Mid-Level',
            'Senior',
            'Management',
            'Executive',
          ],
          isRequired: false,
          visibleIf:
            "{occupation} == 'full_time' || {occupation} == 'part_time'",
        },
        {
          name: 'company_survey_purpose',
          type: 'radiogroup',
          title: 'Is this survey related to your work at the company?',
          isRequired: false,
          visibleIf:
            "{occupation} == 'full_time' || {occupation} == 'part_time'",
          choices: [
            { value: 'yes', text: 'Yes, it is related to my company' },
            { value: 'no', text: 'No, it is for personal purposes' },
          ],
        },
      ],
      visibleIf: "{occupation} == 'full_time' || {occupation} == 'part_time'",
    },
    {
      elements: [
        {
          name: 'freelance_specialization',
          type: 'text',
          title: 'What is your area of specialization?',
          isRequired: false,
          visibleIf: "{occupation} == 'freelance'",
        },
        {
          name: 'freelance_sector',
          type: 'dropdown',
          title: 'Which sector do you mainly work in?',
          isRequired: false,
          visibleIf: "{occupation} == 'freelance'",
          choices: [
            'Technology',
            'Marketing',
            'Finance',
            'Healthcare',
            'Education',
            'Creative & Design',
            'Other',
          ],
        },
        {
          name: 'freelance_survey_purpose',
          type: 'radiogroup',
          title: 'Is this survey related to a client project?',
          isRequired: false,
          visibleIf: "{occupation} == 'freelance'",
          choices: [
            { value: 'yes', text: 'Yes, it is related to client work' },
            { value: 'no', text: 'No, it is for personal purposes' },
          ],
        },
      ],
      visibleIf: "{occupation} == 'freelance'",
    },
    {
      elements: [
        {
          type: 'html',
          name: 'quality_control',
          html: wrapInSurveyElementWithoutFrame(qualityControlHtml),
        },
      ],
    },
    {
      elements: [
        {
          type: 'html',
          name: 'grow_together',
          html: wrapInSurveyElementWithoutFrame(growTogetherHtml),
        },
        {
          type: 'boolean',
          name: 'willing_for_feedback_call',
          title:
            'Would you be open to a quick Google Meet call to share your thoughts on improving the platform later down the line?',
          isRequired: false,
        },
        {
          type: 'text',
          name: 'feedback_contact_email',
          title: "What's the best email to reach you?",
          inputType: 'email',
          visibleIf: '{willing_for_feedback_call} = true',
          isRequired: false,
        },
        {
          type: 'rating',
          name: 'onboarding_feedback_rating',
          title: 'How helpful was this onboarding survey for you?',
          isRequired: true,
          minRateDescription: 'Not helpful at all',
          maxRateDescription: 'Very helpful',
          rateMin: 1,
          rateMax: 5,
          rateStep: 1,
        },
        {
          type: 'comment',
          name: 'onboarding_feedback_comments',
          title: 'Do you have any suggestions or comments to help us improve?',
          isRequired: false,
        },
      ],
    },
  ],
}
