declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    Beacon: any
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    analytics: any
  }
}

import { createContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import { Auth, Hub } from 'aws-amplify'
import { Router } from 'react-router-dom'
import * as QueryString from 'query-string'
import { HubCallback } from '@aws-amplify/core/lib/Hub'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types'

// Services
import { IServiceConfig } from './services/ServiceConfigService'
import UserService from './services/UserService'
import InvitationsService from './services/InvitationsService'
// Components
import { MetaTags } from './components/MetaTags'
import { AppLoader } from './components/AppLoader'
import { NotificationsBox } from './components/UI/NotificationsBox'
import { AccountBlockedBanner } from './components/AccountBlockedBanner'
import ServiceTermsModal from './components/ServiceTermsModal/ServiceTermsModal'
// Helpers
import { includeCurrentLocaleToPath } from './helpers/localeHelpers'
import { setSurveysCountToLocalStorage } from './helpers/surveyHelpers'

// Miscellaneous
import history from './history'
import routes from './routes/routes'

// import { PROFILE_QUESTIONS_SYNC } from './config/surveys'
import {
  APIError,
  INotificationSurveyResponseJSON,
  Survey,
  User,
} from './models'
import HighPriorityNotificationModal from './components/UI/Navbar/components/HighPriorityNotificationModal'
import campaignParams from './helpers/getUtmFromParams'

// GOOGLE ANALYTICS & TAG MANAGER
import GoogleConfig from './config/googleTagManager'
import env from './config/env'

import SentryConfig from './config/sentry'
import * as Sentry from '@sentry/react'

import { slk } from 'survey-core'
slk(process.env.REACT_APP_SURVEYJS_LICENSE_KEY)

import AnalyticsSegmentService from 'src/services/AnalyticsSegmentService'
import { saveProfile } from 'src/routes/surveys/MyProfileSurveyRoute'
import generateConsumer from './channels/consumer'

window.onload = () => {
  console.log('WINDOW.ONLOAD')
  if (
    GoogleConfig.ACTIVE_GOOGLE_TAG_MANAGER &&
    GoogleConfig.GOOGLE_TAG_MANAGER_KEY
  ) {
    import('react-ga').then(ReactGA => {
      ReactGA.initialize(GoogleConfig.GOOGLE_ANALYTICS)
      ReactGA.pageview(window.location.pathname + window.location.search)
    })

    // This imports @types/react-gtm-module
    import('react-gtm-module').then(TagManager => {
      const tagManagerArgs = {
        gtmId: GoogleConfig.GOOGLE_TAG_MANAGER_KEY,
        events: {
          sendPriceCalculated: 'priceCalculated',
          sendQuoteRequested: 'quoteRequested',
          sendUserSignedUp: 'userSignedUp',
          sendUserSignedUpSurveytool: 'userSignedUpSurveytool',
          sendUserTurnedActive: 'userTurnedActive',
        },
      }
      TagManager.default.initialize(tagManagerArgs)
    })
  } else {
    console.log('Google Tag Manager disabled.')
    console.log('Google Tag Analytics disabled.')
  }

  if (env === 'staging' || env === 'production') {
    console.log('initing sentry')
    Sentry.init(SentryConfig)
  }
}
/* END Google Tag Manager */
const PUBLIC_PATHS = ['/', '/sign-in', 'sign-up', '/forgot-password']

/**
 * The context of the logged in user (currentUser).
 */
export type ICurrentUserContext = {
  currentUser: User | null
  setCurrentUser: (user: User | null) => void
}
export const CurrentUserContext = createContext<ICurrentUserContext>({
  currentUser: null,
  setCurrentUser: () => {
    return null
  },
})

/**
 * The context of the service configuration.
 */
export type IServiceConfigContext = {
  serviceConfig: IServiceConfig | null
  setServiceConfig: (config: IServiceConfig | null) => void
}
export const ServiceConfigContext = createContext<IServiceConfigContext>({
  serviceConfig: null,
  setServiceConfig: () => {
    return null
  },
})

/**
 * The context of the survey container.
 */
export type IActiveSurveyContext = {
  activeSurvey: Survey | null
  setActiveSurvey: (survey: Survey | null) => void
}
export const ActiveSurveyContext = createContext<IActiveSurveyContext>({
  activeSurvey: null,
  setActiveSurvey: () => {
    return null
  },
})

const findInvitationInURL = () => {
  const query = QueryString.parse(history.location.search)
  if (
    'ref' in query &&
    query.ref === 'inv' &&
    'inv_token' in query &&
    'inv_id' in query &&
    query.inv_token &&
    query.inv_id
  ) {
    localStorage.setItem('inv_token', query.inv_token as string)
    localStorage.setItem('inv_id', query.inv_id as string)
  }

  if (query.invitation_token) {
    localStorage.setItem('invitationToken', query.invitation_token as string)
  }
}

const App = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [serviceConfig, setServiceConfig] = useState<IServiceConfig | null>(
    null,
  )
  const [activeSurvey, setActiveSurvey] = useState<Survey | null>(null)
  const [initPerformed, setInitPerformed] = useState(false)
  const [realTimeNotifications, setRealTimeNotifications] = useState<
    INotificationSurveyResponseJSON[]
  >([])

  const setSocialSignUpLocalStorageTrigger = (provider: string) => {
    localStorage.setItem('socialSignUp', provider)
  }

  const removeSocialSignUpLocationStorageTrigger = () => {
    localStorage.removeItem('socialSignUp')
  }
  /**
   * Initialization
   *
   * The following code initializes the state of the application.
   * It checks whether the use is already logged in. If so, it fetches
   * user's data from the API.
   */

  useEffect(() => {
    if (!initPerformed) {
      import('./helpers/appHelpers').then(helper => {
        helper.initializeApp(setServiceConfig, setInitPerformed, setCurrentUser)
      })
    }
    // eslint-disable-next-line
  }, [initPerformed])

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  useEffect(() => {
    findInvitationInURL()
    /**
     * NOTE
     * The description below can be misleading because we no longer associate different login methods with a one account
     * END NOTE
     *
     * When user logs in to the AWS Cognito and email has been already used,
     * we link the new account to the existing one with PreSignUp trigger and AWS lambda
     * [precisely: we link social account to the cognito (email+password) account.
     * If email+password account doesn't exists yet for this account, we create a new one.]
     * However, Amplify/Cognito will return an error on the first sign in (linking event) that
     * we handle below. Then we trigger again sign in action
     */
    const query = QueryString.parse(history.location.search)
    if (
      query.error === 'invalid_request' &&
      query.error_description &&
      (query.error_description as string).startsWith(
        'Already found an entry for username',
      )
    ) {
      if (
        (query.error_description as string).startsWith(
          'Already found an entry for username facebook',
        )
      ) {
        setSocialSignUpLocalStorageTrigger('facebook')
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Facebook,
        })
      }
      if (
        (query.error_description as string).startsWith(
          'Already found an entry for username google',
        )
      ) {
        setSocialSignUpLocalStorageTrigger('google')
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      updateUserProfile()
    }
  }, [currentUser])

  useEffect(() => {
    if (currentUser) {
      const subscription = generateConsumer(
        currentUser.id,
      ).subscriptions.create('UserNotificationChannel')

      subscription.received = notification_params => {
        handleNotification(notification_params)
      }
    }
  }, [currentUser])

  const handleNotification = notification_params => {
    const surveyResponse = JSON.parse(
      notification_params.survey_response,
    ) as INotificationSurveyResponseJSON

    setRealTimeNotifications(prev => [...prev, surveyResponse])
  }

  const updateUserProfile = async () => {
    const data = localStorage.getItem('survey_answers_my-profile')

    if (data) {
      const profileData = JSON.parse(data)
      await saveProfile(profileData, () => {
        // Reloading is unnecessary
        window.localStorage.removeItem('survey_answers_my-profile')
      })
    }
  }
  /**
   * Check if user should be redirected somewhere after sign in.
   */
  function checkRedirections(fallback?: string) {
    const noRedirect = localStorage.getItem('noRedirect')

    if (noRedirect) {
      localStorage.removeItem('noRedirect')
      return
    }

    const redirect = localStorage.getItem('after_login')
    if (redirect) {
      window.location.replace(redirect)
      localStorage.removeItem('after_login')
      return true
    } else if (fallback) {
      window.location.replace(fallback)
      return true
    }
    return false
  }

  /**
   * Authentication
   *
   * We use AWS Amplify Hub to fetch user data,
   * whenever he has been logged in and clear CurrentUserContext
   * on sign out.
   */
  // TODO: refactor this callback as it's difficult to customize redirect/extra API call after sign up
  //   this function always redirect to /surveys
  const listener: HubCallback = async ({ payload: { event } }) => {
    let response
    switch (event) {
      case 'signIn':
        response = await UserService.getUserData()
        if (response instanceof APIError) {
          UserService.signOut()
        } else {
          setCurrentUser(response)

          localStorage.setItem('loggedin', 'yes')

          if (response) {
            UserService.updateLastAccess()
          }
          checkRedirections(includeCurrentLocaleToPath('/surveys'))
          // Identify in HelpScout Beacon
          if (window && window.Beacon && response) {
            window.Beacon('identify', {
              name: `${response.first_name}` || 'New user',
              email: response.email,
            })

            if (localStorage.getItem('socialSignup')) {
              removeSocialSignUpLocationStorageTrigger()
            }
          }
        }
        break
      case 'signOut':
        setCurrentUser(null)
        window.location.replace(includeCurrentLocaleToPath('/sign-in'))
        break
    }
  }

  useEffect(() => {
    Hub.listen('auth', listener)
  }, [])

  useEffect(() => {
    if (currentUser) {
      // Handle Invitations
      const invToken = localStorage.getItem('inv_token')
      const invId = localStorage.getItem('inv_id')

      if (!currentUser.hasReceivedInitialCredits() && invToken && invId) {
        InvitationsService.checkInvitation(invToken, invId).then(response => {
          if (response instanceof APIError) {
            toast.error(
              `Please contact customer support regarding your 15 credit bonus: ${response.message}`,
            )
          }
        })
      }

      // Check additional questions
      //   checkAdditionalQuestionsAvailable()

      setSurveysCountToLocalStorage(currentUser)
    }
  }, [currentUser])

  // Sends Segment a page view event
  /* eslint-disable @typescript-eslint/no-explicit-any*/
  let prevPath = null as any

  useEffect(() => {
    history.listen(location => {
      if (location.pathname !== prevPath) {
        prevPath = location.pathname
        AnalyticsSegmentService.page()
      }
    })
  }, [location.pathname])

  //   STORE AND SEND THE UTM PARAMS TO SEGMENT
  useEffect(() => {
    const storedUtmParams = JSON.parse(
      localStorage.getItem('utmParams') || '{}',
    )
    const queryStringUtmParams = campaignParams(document.URL)

    const utmParams = { ...storedUtmParams, ...queryStringUtmParams }

    if (!Object.keys(utmParams).length) return

    if (currentUser) {
      AnalyticsSegmentService.identify(currentUser, utmParams)

      localStorage.removeItem('utmParams')
    } else {
      localStorage.setItem('utmParams', JSON.stringify(utmParams))
    }
  }, [currentUser])

  /**
   * We evaluate user in the above useEffect, but the router takes own action meanwhile,
   * where it redirects logged in users to the sign in page. The side effect is that
   * view is flickering between sign-in and dashboard pages if we try render router
   * before we evaluate the current user. It prevent this, we are going to display
   * the loader until we evaluate the user (initPerformed is true). To make it
   * SEO-friendly, we do it only for non-PUBLIC_PATHS (like landing page).
   */
  if (!PUBLIC_PATHS.includes(history.location.pathname) && !initPerformed) {
    return (
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <ServiceConfigContext.Provider
          value={{ serviceConfig, setServiceConfig }}
        >
          <div className='app'>
            <MetaTags />
            <AppLoader />
          </div>
        </ServiceConfigContext.Provider>
      </CurrentUserContext.Provider>
    )
  }

  const removeNotification = notification => {
    setRealTimeNotifications(prev =>
      prev.filter(noti => !noti.id == notification.id),
    )
  }

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      <ServiceConfigContext.Provider
        value={{ serviceConfig, setServiceConfig }}
      >
        {realTimeNotifications.map((notification, index) => (
          <HighPriorityNotificationModal
            key={index}
            notification={notification}
            onClose={() => removeNotification(notification)}
          />
        ))}
        <ActiveSurveyContext.Provider value={{ activeSurvey, setActiveSurvey }}>
          <div className='app'>
            <MetaTags />
            <Router history={history}>{routes}</Router>
            <NotificationsBox />
            <ServiceTermsModal />
            <AccountBlockedBanner />
          </div>
        </ActiveSurveyContext.Provider>
      </ServiceConfigContext.Provider>
    </CurrentUserContext.Provider>
  )
}

export default App
