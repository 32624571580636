import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
  SignUpContainer as A6YSignUp,
  AuthService,
} from '@altalogy/a6y-react-auth'
import { AuthComponent } from '../../../components/AuthComponent'
import { ButtonStyle } from '../../../components/UI/Button'
import { IAPIResponse } from '../../../services/APIService'
import history from '../../../history'
import { APIError } from '../../../models'
import UserService from '../../../services/UserService'
import { IConsent } from '@altalogy/a6y-react-auth/lib/components/Consents/Consents'
import { ConfirmMigrationHeader } from '../../../components/AuthComponent/components/ConfirmMigrationHeader'
import { ConfirmMigrationForm } from '../../../components/AuthComponent/components/ConfirmMigrationForm'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'

export const ConfirmMigrationContainer = (): JSX.Element => {
  const { t } = useTranslation('auth')

  const [code, setCode] = useState('')
  const [invalidCode, setInvalidCode] = useState<string | undefined>(undefined)
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [consents, setConsents] = useState<IConsent[] | undefined>(undefined)

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const onApiSignUp = async (
    email: string,
    password: string,
    consents?: IConsent[],
  ): Promise<{ success: boolean; errorMessage: string }> => {
    const result = await UserService.checkIfMigratedUser(email, false)
    if (result instanceof APIError) {
      if (result.code === 'MIGRATION_CODE_RESENT') {
        setEmail(email)
        setPassword(password)
        setConsents(consents)
        setStep(1)
        return { success: true, errorMessage: '' }
      }
      return { success: false, errorMessage: result.getMessage() }
    }

    const typedResult = (result as unknown) as IAPIResponse<{
      result: string
    }>

    if (typedResult && typedResult.data && typedResult.data.result) {
      if (typedResult.data.result === 'wrong') {
        toast.error(t('ACCOUNT_CANNOT_BE_MIGRATED_OR_ALREADY_MIGRATED'))
        history.push(includeCurrentLocaleToPath('/sign-in'))
        return { success: true, errorMessage: '' }
      }
    }

    setEmail(email)
    setPassword(password)
    setConsents(consents)
    setStep(1)

    return { success: true, errorMessage: '' }
  }

  const onConfirm = async () => {
    // If success, signin user and getuserdata, redirect to dashboard. Otherwise, display error.
    setInvalidCode(undefined)
    if (!code || code.length === 0) {
      setInvalidCode('CANNOT_BE_BLANK')
    }

    if (!email || email === '' || !password || password === '' || !consents) {
      return false
    }

    const response = await UserService.confirmMigration({
      code: code.replace(/ /g, ''),
      email,
      password,
      consents,
    })
    if (response instanceof APIError) {
      if (response.code === 'MIGRATION_CODE_RESENT') {
        setInvalidCode(response.getMessage())
      }
      return { success: false, errorMessage: response.getMessage() }
    }
    // Automatically sign in the user
    await AuthService.signIn(email, password)
    await UserService.getUserData()
    // Redirect user to the dashboard
    history.push(includeCurrentLocaleToPath('/surveys'))
    return true
  }

  if (step === 1) {
    return (
      <AuthComponent>
        <ConfirmMigrationForm
          code={code}
          onCodeChange={onCodeChange}
          onSubmit={onConfirm}
          invalid={!!invalidCode}
          error={invalidCode}
          setStep={setStep}
        />
      </AuthComponent>
    )
  }

  return (
    <AuthComponent>
      <A6YSignUp
        inputStyles='shadow-input text-sm h-10 w-full border border-blue-500 text-black rounded mt-1 mb-3 p-2'
        buttonStyles={
          'mx-auto my-8 py-3 px-6 shadow text-sm capitalize ' +
          ButtonStyle.PRIMARY
        }
        apiSignUp={onApiSignUp}
        labelStyles='text-xs font-medium capitalize text-blue-500'
        consentsHrefStyle='text-blue-500 mx-1'
        consentInputLabelStyle='a6y-react-auth__checkbox-label mx-0 max-h-full'
        consentTextStyle='text-xs'
        consentSpanStyle='a6y-react-auth__checkbox-label__input--checkmark top-0 w-4 h-4 border border-blue-500'
        consentsStyle='font-medium'
        formLinkStyle='flex flex-wrap items-center justify-center text-sm font-semibold'
        linkStyles='mx-1 text-blue-500 font-medium'
        consentsLabelStyle='flex items-center flex-wrap text-xs mt-3'
        headerComponent={<ConfirmMigrationHeader />}
        showSocialLogin={false}
      />
    </AuthComponent>
  )
}
