import { ForgotPasswordContainer as A6YForgotPassword } from '@altalogy/a6y-react-auth'
import { AuthComponent } from '../../../components/AuthComponent'
import { ButtonStyle } from '../../../components/UI/Button'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'
import history from '../../../history'

export const ForgotPasswordContainer = (): JSX.Element => {
  const onSuccess = () => {
    history.push(includeCurrentLocaleToPath('/sign-in'))
  }
  return (
    <AuthComponent>
      <A6YForgotPassword
        onSuccess={onSuccess}
        inputStyles='shadow-input text-sm h-10 w-full border border-blue-500 text-black rounded mt-1 mb-3 p-2'
        buttonStyles={
          'mx-auto my-8 py-3 px-6 shadow text-sm capitalize ' +
          ButtonStyle.PRIMARY
        }
        labelStyles='text-xs font-medium capitalize text-blue-500'
        consentsHrefStyle='text-blue-500 mx-1'
        consentInputLabelStyle='a6y-react-auth__checkbox-label mx-0 max-h-full'
        consentTextStyle='text-xs'
        consentSpanStyle='a6y-react-auth__checkbox-label__input--checkmark top-0 w-4 h-4 border border-blue-500'
        consentsStyle='font-medium'
        formLinkStyle='flex flex-wrap items-center justify-center text-sm font-semibold'
        linkStyles='mx-1 text-blue-500 font-medium'
        consentsLabelStyle='flex items-center flex-wrap text-xs mt-3'
      />
    </AuthComponent>
  )
}
