import { useTranslation } from 'react-i18next'
import { Card } from '../UI/Card'
import { GoogleLogo, ReviewStars } from './GoogleReviewWidget'
import { TrustPilotLogo } from './TrustPilotWidget'

interface IReview {
  reviewerName: string
  content: string
  rating: number
  date?: Date
  link?: string
  platform: 'google' | 'trustpilot'
  style?: string
}

const reviewPlatforms = {
  google: {
    icon: <GoogleLogo />,
  },
  trustpilot: {
    icon: (
      <div className='relative -top-0.5'>
        <TrustPilotLogo />
      </div>
    ),
  },
}

const ReviewCard = (review: IReview) => {
  const renderPlatform = (reviewPlatform: 'google' | 'trustpilot') => {
    const platform = reviewPlatforms[reviewPlatform]

    return (
      <div className='flex items-center leading-4 gap-1'>
        <span className='text-xs opacity-70'>Posted on</span>
        {platform.icon}
      </div>
    )
  }

  return (
    <div
      className={`${review.style} w-full mb-8`}
      style={{ breakInside: 'avoid' }} // for not breaking masonry layout
    >
      <a href={review.link} className='w-full' target='_blank' rel='noreferrer'>
        <Card
          className='p-5 rounded-xl'
          styles='bg-white border border-gray-600 text-sm'
        >
          <p className='font-bold mb-2'>{review.reviewerName}</p>
          <div className='mb-2'>
            <ReviewStars rating={review.rating} />
          </div>

          <p className='mb-2'>{review.content}</p>

          <p>{renderPlatform(review.platform)}</p>
        </Card>
      </a>
    </div>
  )
}

const Reviews = ({
  reviews = [],
  paid = false,
  withHeader = true,
}: {
  reviews: IReview[]
  paid?: boolean
  withHeader?: boolean
}): JSX.Element => {
  const { t } = useTranslation('app')

  const colsCount = reviews.length >= 3 ? 3 : reviews.length

  return (
    <div>
      {withHeader && (
        <h4 className='text-2xl font-bold mb-12'>
          {' '}
          {paid ? t('REVIEW_SECTION_HEADER_PAID') : t('REVIEW_SECTION_HEADER')}
        </h4>
      )}

      <div
        className='hidden md:block'
        style={{ columnCount: colsCount, columnGap: '2rem' }}
      >
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>

      <div
        className='hidden md:hidden sm:block'
        style={{ columnCount: 2, columnGap: '2rem' }}
      >
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>

      <div
        className='sm:hidden block'
        style={{ columnCount: 1, columnGap: '2rem' }}
      >
        {reviews.map((review, index) => (
          <ReviewCard key={index} {...review} />
        ))}
      </div>
    </div>
  )
}

export default Reviews
