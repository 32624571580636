import AuthImage from 'src/assets/Auth/Get started-light-1.svg'

export const PaymentHeader = ({
  title = 'Sign up to proceed to checkout 💫',
}: {
  title?: string
}): JSX.Element => {
  return (
    <div>
      <div className='flex items-center justify-center md:justify-start font-semibold'>
        {title}
      </div>
      <div className='flex flex-grow items-center justify-center md:hidden'>
        <img className='mx-auto w-56 my-6' src={AuthImage} alt='Welcome' />
      </div>
    </div>
  )
}
